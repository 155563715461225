<template>
  <div
    v-if="fetchedProducts?.length > 0"
    class="block-products-flyout"
  >
    <div class="block-products-flyout__inner">
      <BlockProductsFlyoutItem
        v-for="product in fetchedProducts"
        :key="product.id"
        :product="product"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
    Fetching products
*/

const client = useStrapiClient();
const { getClientID } = useGTM();
const clientId = getClientID();

const filters = {
    items: [
        {
            type: 'product',
            ids: Array.isArray(props.data.products)
                ? props.data.products.map((id) => parseInt(id, 10))
                : [],
        },
    ],
    page: 1,
    pageSize: 6,
    clientId,
};

const { data: fetchedProducts, execute } = useLazyAsyncData(
    `shop-items/find-many/${getUid()}`,
    () => client('/shop-items/find-many', {
        body: filters,
        method: 'POST',
    }),
    {
        immediate: false,
        transform: (res) => res?.items || [],
    },
);
if (props.data.products?.length > 0) {
    execute();
}

</script>

<style lang="scss">
.block-products-flyout {
    @include component-spacing(30px, 60px);
    @include top-level-only {
        grid-column: 1 / -1;
    }
}

.block-products-flyout__inner {
    @include grid-layout(4, 1fr);
    @include fluid('grid-gap', 20px, 121px);

    @include tablet {
        @include grid-layout(4, 1fr);
    }

    @include mobile {
        @include grid-layout(2, 1fr);
    }
}
</style>
